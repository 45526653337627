import React, { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import Media from "react-media"

import { rhythm } from "../utils/typography"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 100, height: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            github
          }
        }
      }
    }
  `)

  const { author, social } = data.site.siteMetadata
  const bio = (
    <Fragment>
      I like to talk about productivity, running, and coffee.
      <br />
      Sometimes I write about software too.
    </Fragment>
  )
  const shortBio = (
    <Fragment>
      I write code and drink coffee.
    </Fragment>
  )

  return (
    <Media queries={{ small: "(max-width: 500px)" }}>
      {matches => {
        const imageSize = matches.small ? rhythm(2) : rhythm(3)
        return (
          <div
            style={{
              display: `flex`,
              marginBottom: rhythm(2),
            }}
          >
            <Image
              fixed={data.avatar.childImageSharp.fixed}
              alt={author.name}
              style={{
                marginRight: rhythm(1 / 2),
                marginBottom: 0,
                width: imageSize,
                height: imageSize,
                borderRadius: `100%`,
              }}
              imgStyle={{
                borderRadius: `50%`,
              }}
            />
            <p>
              The personal blog of{" "}
              <a href={`https://github.com/${social.github}`}>
                <strong>{author.name}</strong>
              </a>
              .<br />
              {matches.small ? shortBio : bio}
            </p>
          </div>
        )
      }}
    </Media>
  )
}

export default Bio
